const Home = () => import('@/views/Index.vue');

export default [
    {
        path: '/',
        name: 'index',
        component: Home,
        meta: {
            title: '首页'
        }
    }
]