const GoodsManage = () => import('@/views/goodsManage/GoodsManage.vue');


export default [
    {
        path: '/goodsManage',
        name: 'goodsManage',
        component: GoodsManage,
        meta: {
            title: '货品管理'
        }
    },
]