import {apiGetCompany,} from "@/api/setting/company";

import WellCache from "well-cache";

let well_cache = new WellCache({prefix: localStorage.getItem('user_uniq_id'), mode: 'IDB'})
let cache_time = 1000 * 60 * 30;
let cache_key = 'company-info';


export async function getCompany() {
    const company = await well_cache.getSync(cache_key);

    if (company.data != null) {
        if (parseInt(company.data.time) + cache_time < parseInt(new Date().getTime())) {
            await resetCompany();
        }
        return JSON.parse(company.data.results);
    } else {
        return resetCompany();
    }
}

export async function resetCompany() {
    let res = await apiGetCompany();

    if (res.data.err_no == 0) {
        let data = {};
        data.results = JSON.stringify(res.data.results);
        data.time = new Date().getTime();
        well_cache.save(cache_key, data);
        res.data.results.brand_name && localStorage.setItem('brand_name', res.data.results.brand_name);
    }

    return res.data.results;
}
