<template class="back">
  <MainHeader v-if="show_flag === 1"/>
</template>

<script>
import '@/css/common.css';
import MainHeader from '@/components/common/Header/MainHeader.vue';
import * as loginUtil from '@/utils/login'

export default {
  name: 'Header',
  data() {
    return {
      show_flag: 0,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.checkView(route);
      },
      immediate: true
    }
  },
  methods: {
    checkView(route) {
      switch (route.path) {
        case '/DataView':
        case '/login':
        case '/forgot':
          this.show_flag = 2;//2表示不显示导航
          break;
        default:
          if (loginUtil.isLogin()) {
            this.show_flag = 1;
          } else {
            this.show_flag = 0;
          }
      }
    }
  },
  components: {
    MainHeader,
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
<style>

</style>
