const HelpManage = () => import('@/views/help/HelpManage.vue');


export default [
    {
        path: '/help-manage',
        name: 'helpManage',
        component: HelpManage,
        meta: {
            title: '帮助中心'
        }
    },

]
