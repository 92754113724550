const CreateProjectDetailView = () => import('@/views/common/CreateProjectDetailView');
const DataView = () => import('@/views/common/DataView.vue');
const Print = () => import('@/views/common/Print.vue');


export default [
    {
        path: '/Print',
        name: 'Print',
        component: Print,
        meta: {
            title: '打印预览'
        }
    },
    {
        path: '/DataView',
        name: 'DataView',
        component: DataView,
        meta: {
            title: '导出查看'
        }
    },
    {
        path: '/CreateProjectDetailView',
        name: 'CreateProjectDetailView',
        component: CreateProjectDetailView,
        meta: {
            title: '生产计划明细查看'
        }
    }
]
