const CreateManage = () => import('@/views/createManage/CreateManage.vue');
const PickGoods = () => import('@/views/createManage/PickGoods.vue');


export default [
    {
        path: '/createManage',
        name: 'createManage',
        component: CreateManage,
        meta: {
            title: '生产管理'
        }
    },
    {
        path: '/createPickGoods',
        name: 'createPickGoods',
        component: PickGoods,
        meta: {
            title: '生产领料货品'
        }
    },
]
