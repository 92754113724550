const About = () => import('@/views/about/About.vue');

export default [
    {
        path: '/about',
        name: 'about',
        component: About,
        meta: {
            title: '关于禾晓'
        }
    }
]
