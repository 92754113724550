<!--应用的主要组件-->
<template>
  <div id="app">
    <Header/>
    <Body/>
    <!--<Foot/>-->
  </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import Body from '@/views/Body.vue'
import Foot from '@/components/common/Foot.vue';

import '@/css/common.css';


export default {
  name: 'App',
  components: {
    Header,
    Body,
    // Foot
  }
}
</script>

<style>
#app {
  /*font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /*background-color: #f5f5f5;*/
}
</style>
