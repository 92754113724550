import Vue from 'vue';

let authorityCheck = new Vue({
    data() {
        return {
            user_rights: undefined,
            plateName:''
        };
    },
    created() {
        // this.getUserRights();
    },
    methods: {
        getUserRights() {
            if (this.user_rights === undefined) {
                this.user_rights = JSON.parse(localStorage.getItem('user_rights'));
            }
        },
        moduleCheck(moduleName) {
            this.getUserRights();
            return this.user_rights[this.plateName].module[moduleName] !== undefined
        },
        pageCheck(pageName) {
            this.getUserRights();
            return this.user_rights[this.plateName].page[pageName] !== undefined
        },
        rightCheck(rightName) {
            this.getUserRights();
            return this.user_rights[this.plateName].rights[rightName] !== undefined
        },
        moduleAllRightCheck(plateName) {
            this.getUserRights();
            return this.user_rights[plateName] !== undefined
        }
    }
});

export default authorityCheck;
