const SettingMenu = () => import('@/views/setting/Setting.vue');


export default [
	{
		path: '/setting',
		name: 'setting',
		component: SettingMenu,
		meta: {
			title: '综合设置'
		}
	},

]