const BuyManage = () => import('@/views/buyManage/BuyManage.vue');


export default [
	{
		path: '/buyManage',
		name: 'buyManage',
		component: BuyManage,
		meta: {
			title: '采购管理'
		}
	},

]