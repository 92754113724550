<template>
  <div id="body">
    <router-view/>
  </div>
</template>

<script>
import Index from '@/router/index';

export default {
  name: 'Body',
  components: {
    Index,

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #body {
        margin:0 auto;
        min-height: 100%;
    }

</style>
