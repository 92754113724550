import Vue from 'vue'
import router from '@/router'
import App from '@/App.vue'
import '@/plugins/iview.js'
import query from '@/plugins/http'
import md5 from 'md5'
import authorityCheck from '@/mixin/authorityCheck';
import clipboard from 'clipboard';

import '@/css/font.css';
import '@/css/common.css';

import * as loginUtil from '@/utils/login'

//注册到vue原型上
Vue.prototype.md5 = md5;
Vue.prototype.$axios = query;
Vue.prototype.aCheck = authorityCheck;
Vue.prototype.clipboard = clipboard;


//应用初始化的启动文件
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }

    loginUtil.checkValidTime();

    next();
});

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
