const Finance = () => import('@/views/finance/Finance.vue');


export default [
	{
		path: '/finance',
		name: 'finance',
		component: Finance,
		meta: {
			title: '财务管理'
		}
	},

]