const SaleManage = () => import('@/views/saleManage/SaleManage.vue');


export default [
	{
		path: '/saleManage',
		name: 'saleManage',
		component: SaleManage,
		meta: {
			title: '销售管理'
		}
	},

]