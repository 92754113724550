import axios from 'axios';
import router from '@/router';

/**
 * null => ''
 * @param {*} data 要处理的数据
 */
function null2str(data) {
    for (let x in data) {
        if (data[x] === null) { // 如果是null 把直接内容转为 ''
            data[x] = '';
        } else {
            if (Array.isArray(data[x])) { // 是数组遍历数组 递归继续处理
                data[x] = data[x].map(z => {
                    return null2str(z);
                });
            }
            if (typeof(data[x]) === 'object') { // 是json 递归继续处理
                data[x] = null2str(data[x]);
            }
        }
    }
    return data;
}

const instance = axios.create({
    headers: {
        'content-type': 'application/json;charset=UTF-8',
    },
    timeout: 30000,
    withCredentials: true
});

// 添加请求拦截器
instance.interceptors.request.use(config => {
    // 在发送请求之前做某事，比如说 设置token
    config.headers['Authorization'] = localStorage.getItem('api_token');
    config.headers['AuthType'] = 1;//1表示网站
    return config;
}, error => {
    // 请求错误时做些事
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(response => {
    response.data = null2str(response.data);

    // 对响应数据做些事
    if (response.status === 200) {
        if (response.data && response.data.err_no === 401) {
            localStorage.setItem('is_login', 0);
            router.push({name: 'login'});
        }
    }
    return response;
}, error => {
    return Promise.reject(error.response?.data); // 返回接口返回的错误信息
});

export default instance;
